<template>
    <div class="about">
        <p><b>Уважаемые клиенты и бренды!</b></p>
        <p>
            Мы рады приветствовать вас на нашем сайте, где вы сможете ознакомиться с услугами нашей компании по пошиву на заказ и брендированию одежды. 
            Мы предлагаем вам уникальную возможность создать собственный стиль и подчеркнуть свою корпоративную идентичность через наши индивидуальные решения.
        </p>
        <p>
            Наша компания специализируется на пошиве на заказ, предоставляя вам возможность создавать одежду, которая полностью соответствует вашим потребностям и требованиям. 
            Независимо от того, нужны ли вам фирменные формы для вашей команды, корпоративная одежда для сотрудников или оригинальные элементы брендирования, мы готовы воплотить ваши идеи в реальность.
        </p>
        <p>
            Мы понимаем, что каждый бренд имеет свою уникальность и характер, и поэтому наша команда дизайнеров работает с вами непосредственно, чтобы создать концепцию, отражающую вашу уникальность. 
            Мы используем только лучшие материалы и высокотехнологичное оборудование, чтобы обеспечить высокое качество и долговечность вашей брендированной одежды.
        </p>
        <!-- <p>
            На нашем сайте вы  сможете ознакомиться с нашим портфолио и отзывами клиентов. 
            Мы также предлагаем удобные способы связи, чтобы вы могли обсудить свои требования и задать любые вопросы.
        </p> -->
        <p>
            Благодарим вас за проявленный интерес к нашей компании. 
            Мы с нетерпением ждем возможности создать для вас уникальную и выразительную брендированную одежду, которая станет отражением вашего стиля и поможет выделиться среди конкуренции.
        </p>
        <p><b>Добро пожаловать в мир индивидуального стиля и брендирования!</b></p>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    export default {
        name: 'Entity',
        metaInfo() {
            return {
                title: 'О компании'
            }
        },
        components: {
        },
        data: () => ({
        }),
        computed: {
            ...mapState('categories', {
                categories: state => state.entities
            }),
        },
        async mounted() {
            await new Promise((resolve, reject) => setTimeout(resolve, 300));
            this.loaded = true;
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    .about {
        width: 100%;
        max-width: 1240px;

        p {
            font-size: 16px;
        }
    }
</style>
